import {Component, Input} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {CgBusyDirective} from 'angular-busy2';
import {Subscription} from 'rxjs';

@Component({
  selector: 'alc-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  standalone: true,
  imports: [CgBusyDirective, MatIconModule]
})
export class MessageComponent {
  @Input() icon: string;
  @Input() iconClass: string;
  @Input() busy: boolean | Subscription;
}
