import {Component, OnInit} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatOptionModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslocoModule} from '@jsverse/transloco';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {CgBusyDirective} from 'angular-busy2';
import {Subscription} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {DeviceActivationEntity} from '../../../../../../alcedo/src/app/shared/entities/devices/device-activation.entity';
import {Alcedo7User} from '../../../../../../alcedo/src/app/shared/entities/user/avelon-user.service';
import {CurrentUserEntity} from '../../../../../../alcedo/src/app/shared/entities/user/current-user.entity';
import {orderByLocale} from '../../../../../../alcedo/src/app/shared/services/order-by-locale.service';
import {DeviceService} from '../../../core/services/device.service';
import {IoTService} from '../../../core/services/iot.service';
import {AppSelectDirective} from '../../shared/app-select/app-select.directive';

@Component({
  selector: 'app-confirm-client',
  templateUrl: './confirm-client.component.html',
  styleUrls: ['./confirm-client.component.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    AppSelectDirective,
    MatSelectModule,
    MatOptionModule,
    MatCheckboxModule,
    FormsModule,
    MatButtonModule,
    CgBusyDirective,
    TranslocoModule
  ]
})
export class ConfirmClientComponent implements OnInit {
  Alcedo7User = Alcedo7User;
  clientId: number;
  replaceDevice = false;
  loading$: Subscription;
  private activationCode: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private deviceActivationEntity: DeviceActivationEntity,
    private currentUserEntity: CurrentUserEntity,
    private deviceService: DeviceService,
    private iotService: IoTService,
    private oidcSecurityService: OidcSecurityService
  ) {}

  ngOnInit() {
    this.currentUserEntity.getClients().subscribe(clients => (Alcedo7User.clients = orderByLocale(clients, 'name')));
    this.activationCode = this.route.snapshot.paramMap.get('activationCode');
    if (!this.activationCode) {
      this.router.navigate(['activate', this.activationCode]);
      return;
    }
    this.clientId = Alcedo7User.currentUser.clientId;
  }

  logout() {
    this.oidcSecurityService
      .logoff(undefined, {
        urlHandler: (url: string): void => {
          // override default logout redirect uri
          let uri = url.replace(/post_logout_redirect_uri.*/, '');
          uri += 'post_logout_redirect_uri=' + encodeURIComponent(window.location.origin + '/activate/' + this.activationCode + '/login');
          this.oidcSecurityService.logoffLocal();
          location.href = uri;
        }
      })
      .subscribe();
  }

  next() {
    if (this.replaceDevice) {
      this.router.navigate(['activate', this.activationCode, 'client', this.clientId, 'replace']);
    } else {
      this.activateDevice();
    }
  }

  activateDevice() {
    this.loading$ = this.deviceActivationEntity
      .deviceActivate(this.clientId, this.activationCode)
      .pipe(
        switchMap(() => {
          return this.deviceService.getDevice(this.activationCode);
        })
      )
      .subscribe(device => {
        device.activated = true;
        device.clientId = this.clientId;
        device.scanned = false;
        const deviceFromList = this.iotService.getDeviceByActivationCode(this.activationCode);
        deviceFromList.scanned = false;
        this.deviceService.loadDevices(this.clientId).subscribe(devices => {
          devices.push(device);
        });
        this.router.navigate(['activate', this.activationCode, 'change-name']);
      });
  }
}
